import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  Layout,
  Database,
  Server,
  Terminal,
  ChevronRight,
  Star,
  Award,
  Clock,
} from "lucide-react";

const AnimatedStat = ({ value, label, delay, icon: Icon }) => {
  const [count, setCount] = useState(0);
  const finalValue = parseInt(value);
  const duration = 2000;
  const fps = 60;
  const frames = (duration / 1000) * fps;
  const increment = finalValue / frames;

  useEffect(() => {
    let currentCount = 0;
    let frame = 0;

    const timeoutId = setTimeout(() => {
      const interval = setInterval(() => {
        frame++;
        currentCount = Math.min(currentCount + increment, finalValue);
        setCount(Math.floor(currentCount));

        if (frame >= frames) {
          clearInterval(interval);
          setCount(finalValue);
        }
      }, 1000 / fps);

      return () => clearInterval(interval);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [finalValue, increment, frames, delay]);

  return (
    <motion.div
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5, delay: delay / 1000 }}
      className="relative text-center bg-white/80 backdrop-blur-lg rounded-xl p-8 hover:bg-white/90 transition-all duration-300 shadow-lg group"
    >
      <div className="absolute -top-6 left-1/2 -translate-x-1/2 bg-theme-600 p-3 rounded-full shadow-lg">
        <Icon className="w-6 h-6 text-white" />
      </div>
      <h3 className="text-5xl font-bold bg-gradient-to-r from-theme-600 to-theme-400 bg-clip-text text-transparent mt-4">
        {count}
        {value.includes("+") ? "+" : ""}
      </h3>
      <p className="text-main-black mt-2 font-medium">{label}</p>
    </motion.div>
  );
};

const SkillCard = ({ category, index }) => (
  <motion.div
    initial={{ y: 20, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{ duration: 1, delay: 0.2 + index * 0.1 }}
    className="bg-white/80 backdrop-blur-lg rounded-xl p-8 hover:bg-white/90 transition-all duration-300 shadow-lg group"
  >
    <div className="flex items-center mb-6">
      <div className="bg-theme-600/10 p-3 rounded-xl group-hover:bg-theme-600 transition-colors duration-300">
        <category.icon className="w-6 h-6 text-theme-600 group-hover:text-white" />
      </div>
      <h3 className="text-md font-bold text-main-black ml-4">
        {category.title}
      </h3>
    </div>
    <ul className="space-y-3">
      {category.skills.map((skill) => (
        <li
          key={skill}
          className="flex items-center space-x-2 text-main-black/80 hover:text-main-black transition-colors duration-200"
        >
          <ChevronRight className="w-4 h-4 text-theme-600" />
          <span className="font-medium text-sm">{skill}</span>
        </li>
      ))}
    </ul>
  </motion.div>
);

const About = () => {
  const skillCategories = [
    {
      title: "Frontend Development",
      icon: Layout,
      skills: [
        "ReactJS",
        "TypeScript",
        "React-Redux",
        "HTML5/CSS3",
        "JavaScript (ES6+)",
      ],
    },
    {
      title: "Backend & API",
      icon: Server,
      skills: [
        "NodeJS",
        "ExpressJS",
        "REST API",
        "API Integration",
        "MVC Pattern",
      ],
    },
    {
      title: "Database & State",
      icon: Database,
      skills: [
        "MongoDB",
        "State Management",
        "Redux",
        "Data Flow",
        "API Integration",
      ],
    },
    {
      title: "Development Tools",
      icon: Terminal,
      skills: ["Git/GitHub", "AWS Hosting", "Npm/Yarn", "Agile/Scrum", "Jira"],
    },
  ];

  const stats = [
    { label: "React Projects", value: "10+", icon: Star },
    { label: "Technologies", value: "15+", icon: Award },
    { label: "Years Experience", value: "3+", icon: Clock },
  ];

  return (
    <div
      id="about"
      className="min-h-screen bg-gradient-to-b from-white to-theme-100 text-main-black py-20 px-4 relative overflow-hidden"
    >
      {/* Animated Background Blobs */}
      <div className="absolute inset-0 opacity-20">
        <div className="absolute top-0 -left-4 w-72 h-72 bg-theme-300 rounded-full mix-blend-multiply filter blur-xl animate-blob"></div>
        <div className="absolute top-0 -right-4 w-72 h-72 bg-theme-400 rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-2000"></div>
        <div className="absolute top-64 left-20 w-72 h-72 bg-theme-500 rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-4000"></div>
      </div>

      <div className="max-w-6xl mx-auto relative z-10">
        {/* Header Section */}
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-20"
        >
          <h1 className="text-6xl font-bold text-main-black mb-4">About Me</h1>
          <div className="h-1.5 w-32 bg-gradient-to-r from-theme-400 to-theme-600 mx-auto rounded-full"></div>
        </motion.div>

        {/* Profile Summary */}
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="mb-20"
        >
          <div className="bg-white/80 backdrop-blur-lg rounded-2xl p-10 hover:bg-white/90 transition-all duration-300 shadow-lg">
            <p className="text-lg md:text-xl text-center leading-relaxed text-main-black/80">
              As a dedicated{" "}
              <span className="font-semibold text-theme-600">
                React.js Developer
              </span>{" "}
              with hands-on experience from MD Tech, I specialize in building
              modern web applications using the MERN stack. My expertise spans
              across frontend development with React.js, state management with
              Redux, and backend integration using Node.js and Express. Through
              my work on various projects, I've demonstrated proficiency in
              creating responsive, user-friendly interfaces and implementing
              robust data management solutions. I'm passionate about clean code,
              efficient state management, and creating seamless user
              experiences.
            </p>
          </div>
        </motion.div>

        {/* Stats Section */}
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, delay: 0.4 }}
          className="grid md:grid-cols-3 gap-12 mb-20"
        >
          {stats.map((stat, index) => (
            <AnimatedStat
              key={stat.label}
              value={stat.value}
              label={stat.label}
              delay={index * 200}
              icon={stat.icon}
            />
          ))}
        </motion.div>

        {/* Skills Categories */}
        <div className="grid md:grid-cols-4 gap-8">
          {skillCategories.map((category, idx) => (
            <SkillCard key={category.title} category={category} index={idx} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
