import React, { useRef, useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';

const Navbar = () => {
  const navRef = useRef();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('home');
  const location = useLocation();
  const navigate = useNavigate();
  const isMainPage = location.pathname === '/';

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 50);
      
      if (isMainPage) {
        if (scrollPosition < 700) setActiveSection('home');
        else if (scrollPosition < 1440) setActiveSection('about');
        else if (scrollPosition < 2600) setActiveSection('project');
        else setActiveSection('contact');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMainPage]);

  const showNavbar = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollTo = (value) => {
    if (isMainPage) {
      scroll.scrollTo(value, {
        duration: 800,
        smooth: 'easeInOutQuart',
      });
    }
  };

  const handleNavigation = (section, scrollValue) => {
    showNavbar();
    if (isMainPage) {
      scrollTo(scrollValue);
    } else {
      navigate(`/#${section}`);
    }
  };

  const NavLink = ({ section, scrollValue, children }) => (
    <button
      onClick={() => handleNavigation(section, scrollValue)}
      className={`relative px-4 py-2 transition-all duration-300 hover:text-white
        ${activeSection === section && isMainPage ? 'text-white' : 'text-main-blue'}
        group w-full md:w-auto text-center`}
    >
      {children}
      <span className={`absolute bottom-0 left-0 h-0.5 bg-theme-300 transition-all duration-300 
        group-hover:w-full
        ${activeSection === section && isMainPage ? 'w-full' : 'w-0'}`}
      />
    </button>
  );

  return (
    <header 
      className={`flex justify-between items-center sticky top-0 z-30 px-4 md:px-8 h-[75px]
        transition-all duration-300 ease-in-out
        ${isScrolled 
          ? 'bg-opacity-90 backdrop-blur-sm bg-main-black shadow-lg' 
          : 'bg-main-black'}`}
    >
      <Link 
        to="/" 
        className="text-xl md:text-[25px] font-bold relative group"
        onClick={() => isMainPage && scrollTo(0)}
      >
        <span className="text-main-blue hover:text-theme-300 transition-colors duration-300">
          {"<Shubh_Kakadia/>"}
        </span>
        <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-theme-300 transition-all duration-300 group-hover:w-full" />
      </Link>

      <nav
        ref={navRef}
        className={`fixed md:relative top-[75px] md:top-0 right-0 
          w-full md:w-auto h-[calc(100vh-75px)] md:h-auto
          flex flex-col md:flex-row items-center 
          space-y-4 md:space-y-0 md:space-x-8 
          bg-main-black md:bg-transparent
          p-4 md:p-0
          transform transition-transform duration-300 ease-in-out
          ${isMenuOpen ? 'translate-x-0' : 'translate-x-full md:translate-x-0'}
          `}
      >
        <NavLink section="home" scrollValue={0}>Home</NavLink>
        <NavLink section="about" scrollValue={701}>About</NavLink>
        <NavLink section="project" scrollValue={1800}>Projects</NavLink>
        <NavLink section="contact" scrollValue={2500}>Contact</NavLink>
        
        <Link 
          to="/resume" 
          className="w-full md:w-auto text-center px-4 py-2 text-main-blue border border-main-blue rounded 
            hover:bg-theme-300 hover:border-theme-300 hover:text-main-black
            transition-all duration-300 ease-in-out"
          onClick={showNavbar}
        >
          Resume
        </Link>
      </nav>

      <button
        className="md:hidden p-1 cursor-pointer bg-transparent outline-none 
          text-[20px] text-main-blue hover:text-white transition-colors duration-300"
        onClick={showNavbar}
      >
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </button>
    </header>
  );
};

export default Navbar;