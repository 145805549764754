import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import Typewriter from "./Typewriter";
import About from "./About";
import Contact from "./Contact";
import Projects from "./Projects";
import ScrollToTop from "./scrollToTop";
import shubhImg from "../assets/photo-removebg-preview.png";

const Landing = () => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();
  const [particles, setParticles] = useState([]);

  // Generate tech-related words based on your skills
  const techWords = [
    "ReactJs",
    "JavaScript",
    "Python",
    "MongoDB",
    "NodeJS",
    "ExpressJs",
    "AWS",
    "TypeScript",
    "Git",
    "SQL",
    "Redux",
    "RESTful APIs",
    "Bootstrap",
    "CSS",
    "TailwindCSS",
  ];

  // Create floating particle
  const createParticle = useCallback(() => {
    return {
      id: Math.random(),
      x: Math.random() * window.innerWidth,
      y: Math.random() * window.innerHeight,
      word: techWords[Math.floor(Math.random() * techWords.length)],
      speed: 0.5 + Math.random() * 1,
      size: 10 + Math.random() * 20,
      opacity: 0.1 + Math.random() * 0.3,
    };
  }, []);

  // Initialize particles
  useEffect(() => {
    const particlesArray = Array.from({ length: 15 }, createParticle);
    setParticles(particlesArray);

    const interval = setInterval(() => {
      setParticles((prev) =>
        prev.map((particle) => ({
          ...particle,
          y: particle.y - particle.speed,
          opacity: particle.opacity + Math.sin(Date.now() / 1000) * 0.01,
          ...(particle.y < -50 && {
            y: window.innerHeight + 50,
            x: Math.random() * window.innerWidth,
          }),
        }))
      );
    }, 50);

    return () => clearInterval(interval);
  }, [createParticle]);

  // Your existing scroll handling
  function handlescrolling() {
    const scrollY = window.scrollY;
    setScrollPosition(scrollY);
    const mediaQuery = window.matchMedia("(max-width: 500px)");

    if (mediaQuery.matches) {
      if (scrollY < 650) navigate("/");
      if (scrollY > 650 && scrollY < 1300) navigate("/about");
      if (scrollY > 1300 && scrollY < 2200) navigate("/project");
      if (scrollY >= 2500) navigate("/contact");
    } else {
      if (scrollY < 650) navigate("/");
      if (scrollY > 650 && scrollY < 1300) navigate("/about");
      if (scrollY > 1300 && scrollY < 2200) navigate("/project");
      if (scrollY >= 2500) navigate("/contact");
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handlescrolling);
    return () => window.removeEventListener("scroll", handlescrolling);
  }, [navigate]);

  const openLinkedIn = () => {
    window.open("https://www.linkedin.com/in/shubh-kakadia/");
  };

  const openGitHub = () => {
    window.open("https://github.com/shubhkakadia");
  };

  return (
    <div
      id="home"
      className="relative min-h-screen overflow-hidden bg-main-black"
    >
      {/* Animated Background */}
      <div className="fixed inset-0 z-0">
        {/* Matrix-like falling tech words */}
        {particles.map((particle) => (
          <div
            key={particle.id}
            className="absolute text-main-blue pointer-events-none transition-all duration-1000 font-mono"
            style={{
              transform: `translate(${particle.x}px, ${particle.y}px)`,
              fontSize: `${particle.size}px`,
              opacity: particle.opacity,
            }}
          >
            {particle.word}
          </div>
        ))}

        {/* Gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-main-black via-transparent to-main-black opacity-90"></div>

        {/* Grid pattern */}
        <div className="absolute inset-0 bg-grid-pattern opacity-5"></div>
      </div>

      {/* Content */}
      <div className="relative z-10">
        <div className="namepage container animate-fade-in-up">
          <div className="block-1 flex justify-center items-center">
            <div className="fade-in" style={{ animationDelay: "0.3s" }}>
              <div className="text-[30px] justify-center items-center md:text-[50px] text-white flex gap-3">
                <h2 className="font-playwrite">Hello</h2>
                <h2 className="text-main-blue font-playwrite">I'm </h2>
              </div>
              <div>
                <Typewriter />
              </div>
              <div className="flex justify-center text-main-blue text-[20px] liner">
                Code, design, launch – the full-stack developer's journey.
              </div>
              <div className="flex justify-center pt-10">
                <FaLinkedin
                  onClick={openLinkedIn}
                  title="LinkedIn Profile"
                  className="social-icon text-main-blue text-[35px] mr-5 ml-5 cursor-pointer hover:scale-110 transition-transform"
                />
                <FaGithub
                  onClick={openGitHub}
                  title="GitHub Profile"
                  className="social-icon text-main-blue text-[35px] mr-5 ml-5 cursor-pointer hover:scale-110 transition-transform"
                />
              </div>
            </div>
          </div>
          <div className="block-2 flex justify-center items-center">
            <div className="fade-in" style={{ animationDelay: "0.6s" }}>
              <img
                src={shubhImg}
                alt=""
                className={`rounded-full picture profile-image ${
                  isImageLoaded ? "opacity-100" : "opacity-0"
                }`}
                width="450"
                onLoad={() => setIsImageLoaded(true)}
              />
            </div>
          </div>
        </div>
        <ScrollToTop />
        <About />
        <Projects />
        <Contact />
      </div>

      <style jsx>{`
        .bg-grid-pattern {
          background-image: linear-gradient(
              to right,
              rgba(55, 65, 81, 0.1) 1px,
              transparent 1px
            ),
            linear-gradient(
              to bottom,
              rgba(55, 65, 81, 0.1) 1px,
              transparent 1px
            );
          background-size: 50px 50px;
        }

        .profile-image {
          transition: transform 0.5s ease-in-out;
          animation: float 6s ease-in-out infinite;
        }

        .profile-image:hover {
          transform: scale(1.05);
        }

        @keyframes float {
          0% {
            transform: translateY(0px);
          }
          50% {
            transform: translateY(-20px);
          }
          100% {
            transform: translateY(0px);
          }
        }

        .fade-in {
          opacity: 0;
          animation: fadeIn 1s ease-in forwards;
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );
};

export default Landing;
